var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group-info" }, [
    _vm.groupInfo.group_buy_open_info.surplus_num == 0
      ? _c("h3", { staticClass: "title" }, [
          _c("span", { staticClass: "gray-txt" }, [_vm._v("拼团成功")]),
        ])
      : _c("h3", { staticClass: "title" }, [
          _c("span", { staticClass: "gray-txt" }, [_vm._v("还差")]),
          _c("span", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.groupInfo.group_buy_open_info.surplus_num)),
          ]),
          _c("span", { staticClass: "gray-txt" }, [_vm._v("人即可拼团成功")]),
        ]),
    _c("div", { staticClass: "group-list-wrap" }, [
      _c(
        "ul",
        { staticClass: "group-list" },
        _vm._l(
          _vm.groupInfo.group_buy_open_info.people_limit,
          function (item, index) {
            return _c(
              "li",
              {
                key: index,
                class: {
                  active: index < _vm.groupInfo.group_buy_user_list.length,
                },
              },
              [
                index < _vm.groupInfo.group_buy_user_list.length &&
                _vm.groupInfo.group_buy_user_list[index].user_info.heagurl
                  ? _c("img", {
                      attrs: {
                        src: _vm.groupInfo.group_buy_user_list[index].user_info
                          .heagurl,
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/ic_head_default@3x.png"),
                        alt: "",
                      },
                    }),
                index === 0
                  ? _c("span", { staticClass: "head-badge" }, [_vm._v("团长")])
                  : _vm._e(),
              ]
            )
          }
        ),
        0
      ),
      _vm.groupInfo.group_buy_open_info.surplus_num != 0
        ? _c(
            "p",
            { staticClass: "countdown-wrap" },
            [
              _vm.end_time !== 0
                ? _c("span", { staticClass: "gray-txt" }, [_vm._v("剩余")])
                : _vm._e(),
              _c(
                "yd-countdown",
                {
                  attrs: {
                    time: _vm.end_time,
                    timetype: "timestamp",
                    "done-text": "",
                    format: "{%h}:{%m}:{%s}",
                  },
                },
                [
                  _c("span", { staticClass: "time" }, [
                    _vm._v("{%h}:{%m}:{%s}"),
                  ]),
                ]
              ),
              _vm.end_time === 0
                ? _c("span", { staticClass: "gray-txt" }, [_vm._v("已")])
                : _vm._e(),
              _c("span", { staticClass: "gray-txt" }, [_vm._v("失效")]),
            ],
            1
          )
        : _vm._e(),
      _vm.end_time !== 0 && _vm.groupInfo.group_buy_open_info.surplus_num != 0
        ? _c(
            "div",
            { staticClass: "group-btn", on: { click: _vm.showDialog } },
            [_vm._v("邀请好友")]
          )
        : _vm._e(),
    ]),
    _vm.show_guide
      ? _c("div", {
          staticClass: "guide-bg",
          on: {
            click: function ($event) {
              _vm.show_guide = false
            },
          },
        })
      : _vm._e(),
    _vm.show_guide
      ? _c("div", { staticClass: "guide-box" }, [
          _c("div", { staticClass: "guide-top" }),
          _c(
            "div",
            {
              staticClass: "flex-center",
              on: {
                click: function ($event) {
                  _vm.show_guide = false
                },
              },
            },
            [
              _c("img", {
                staticClass: "guide-btn",
                attrs: {
                  src: require("../../assets/button_iknow@3x.png"),
                  alt: "",
                },
              }),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }