<template>
	<div v-if="order_detail" class="order-detail">
		<HeadNav/>
		
		<div class="order-top">
		  <div class="top-left">
		    <img src="~@/assets/icon_dingdan_daifukuan@2x.png" alt />
		    <span
					v-if="getOrderState(order_detail.order_status) == '待付款' &&
					type===3 && order_detail.is_password == 0"
				>待提交</span>
		    <span
					v-else-if="getOrderState(order_detail.order_status) == '待付款' &&
					type === 3 && order_detail.is_password == 1"
				>待审批</span>
		    <span v-else>
					{{getOrderState(order_detail.order_status)}}
				</span>
		  </div>
		  <div class="top-right">
		    <span v-if="getOrderKey(order_detail.order_status) === 'PAY'">
		      <yd-countdown
						:time="order_detail.close_time"
						timetype="timestamp"
						:callback='countDone'
					>
		        {%m}:{%s}
		      </yd-countdown>
		    </span>
		  </div>
		</div>
		<div v-if="infoStatus" class="order-address">
		  <p class="user-info">
		    <span class="user-name fs-weight">
					{{order_detail.realname}}
				</span>
		    <span class="user-phone fs-weight">
					{{order_detail.mobile | hideMobile}}
				</span>
		  </p>
		  <p class="user-address">{{order_detail.address}}</p>
		  <div class="order-line"></div>
		</div>
		<div
			v-if="getOrderState(order_detail.order_status) == '已拒绝'"
			class="refuse-reason-wrap"
		>
		  <h4 class="title fs-weight">拒绝理由</h4>
		  <p class="refuse-reason">{{order_detail.refuse_reason}}</p>
		</div>
		<!-- / 拒绝理由 -->
		
		<!-- 查看物流 -->
		<div
			v-if="logistics_list.length > 0 && logistics_status === 0"
			class="material-box"
			@click="lookMaterial()"
		>
		  <div class="material-time">
		    <p>{{date}}</p>
		    <p>{{time}}</p>
		  </div>
		  <div class="material-info">
		    <div class="info">
		      <p>{{logistics_list[0].status}}</p>
		    </div>
		    <img src="@/assets/ic_jinru_right_black@3x.png" alt />
		  </div>
		</div>
		<div
			v-else-if="logistics_status === 0 && !logistics_list"
			class="material-box"
			@click="lookMaterial()"
		>
		  <div class="material-info">
		    <div class="info">
		      <p>{{logistics_info}}</p>
		    </div>
		    <img src="@/assets/ic_jinru_right_black@3x.png" alt />
		  </div>
		</div>
		<div
			v-else-if="logistics_status === -1"
			class="material-box"
		>
		  <div class="material-info">
		    <div class="info">
		      <p>{{logistics_info}}</p>
		    </div>
		  </div>
		</div>
		<div
			v-if="!order_detail.items &&
			order_detail.item_list[0].recharge_type == 1 &&
			order_detail.kami"
			class="material-box"
		>
		  <div class="material-info">
		    <div class="info">
		      <p>卡密信息</p>
		      <p>{{order_detail.kami}}</p>
		    </div>
		  </div>
		</div>
		
		<!-- 拼团成员信息 -->
		<div v-if="group_data.id" style="background: #fff;">
		  <group-member
				:groupInfo='group_data'
				:pay_order_id="order_detail.pay_order_id"
			></group-member>
		</div>
		<div
			v-if="ORDER_TYPE == 2 && order_detail.return_express_number"
			class="order-line"
			style="padding: 0 .3rem;background: #fff;"
		>
		  <p class="fs-weight">商家换货物流单号</p>
		  <p>{{order_detail.return_express_number}}</p>
		</div>
		<div class="order-content">
		  <div
				v-if="order_detail.items"
				class="goods-shop fs-weight"
			>{{getSourceType(order_detail.items)}}</div>
		  <div
				v-else-if="order_detail.item_list"
				class="goods-shop fs-weight"
			>{{getSourceType(order_detail.item_list)}}
		  </div>
		  <order-goods
				:goodsList="order_detail.items || order_detail.item_list"
			></order-goods>
			
		  <div v-if="ORDER_TYPE == 1" class="order-info">
		    <div class="info-title fs-weight">商品信息</div>
				<div
					v-if="Number(order_detail.reduce_integral) || Number(order_detail.reduce)"
					class="info-item"
				>
					<template v-if="type == 2">
						<p>满减</p>
						<p>{{ order_detail.reduce_integral }}积分</p>
					</template>
					<template v-else>
						<p>满减</p>
						<p>{{ order_detail.reduce }}</p>
					</template>
				</div>
				<div
					v-if="Number(order_detail.vouchers_integral) || Number(order_detail.vouchers_amount)"
					class="info-item">
					<template v-if="type == 2">
						<p>优惠券</p>
						<p>{{ order_detail.vouchers_integral }}积分</p>
					</template>
					<template v-else>
						<p>优惠券</p>
						<p>{{ order_detail.vouchers_amount }}</p>
					</template>
				</div>
		    <div class="info-item">
		      <template v-if="type == 2">
		        <p>商品总价</p>
						<p v-if="order_detail.payway === 3 || order_detail.payway === 10">
							{{ order_detail.integral_pay_amount != '0.00' ? `${order_detail.integral_pay_amount}元` : `` }}
							{{ order_detail.integral_pay_amount != '0.00' && order_detail.integral != '0.00'? '+' : '' }}
							{{ order_detail.integral != '0.00' ? `${order_detail.integral}积分` : `` }}
						</p>
						<p v-else>{{`${order_detail.total_amount}元`}}</p>
					</template>
		      <template v-else>
		        <p>商品总价</p>
		        <p class="fs-weight">¥{{order_detail.total_amount}}</p>
		      </template>
		    </div>
		  </div>

		  <div v-if="ORDER_TYPE == 2" class="order-info">
		    <div class="order-line">
		      <p class="fs-weight">申请理由</p>
		      <p>{{order_detail.content}}</p>
		    </div>
		    <div v-if="order_detail.express_number" class="order-line">
		      <p class="fs-weight">换货退回物流编号</p>
		      <p>{{order_detail.express_number}}</p>
		    </div>
		    <div v-show="order_detail.operate_type != 4" class="order-line">
		      <p class="fs-weight">退款金额</p>
		      <p v-if="type == 2">
						{{ order_detail.refund_money != '0.00' ? `${order_detail.refund_money}元` : `` }}
						{{ order_detail.refund_money != '0.00' && order_detail.refund_integral != '0.00'? '+' : '' }}
						{{ order_detail.refund_integral != '0.00' ? `${order_detail.refund_integral}积分` : `` }}  
					</p>
					<p v-else>￥{{order_detail.refund_money}}</p>
		    </div>
		    <div class="order-line">
		      <p class="fs-weight">申请时间</p>
		      <p>{{order_detail.create_time | formatDateTime}}</p>
		    </div>
		  </div>

		  <!-- 售后申请原因 -->
		  <div v-if="ORDER_TYPE == 2" class="after-reason">
		    <div class="fs-weight after-title">申请原因说明</div>
		    <p class="reason-title">{{order_detail.reason}}</p>
		    <div class="reason-imgs">
		      <img
						v-for="(item, index) in reasonImgs"
						:key="index"
						:src="item" alt
					/>
		    </div>
		  </div>
			
		  <!-- 订单汇总 -->
		  <div
				v-if="ORDER_TYPE == 1"
				class="order-collect"
				@click="orderCollect()"
			>
		    <p class="fs-weight">订单汇总</p>
		    <p class="look">
		      查看总订单
		      <img src="~@/assets/ic_arrow_right_gray@2x.png" alt />
		    </p>
		  </div>
			
		  <div v-if="ORDER_TYPE == 1" class="order-info">
		    <div class="order-line" v-if="order_detail.recharge_account">
		      <p class="fs-weight">充值账号</p>
		      <p>{{order_detail.recharge_account}}</p>
		    </div>
		    <div class="order-line">
		      <p class="fs-weight">订单备注</p>
		      <p>{{order_detail.user_remark}}</p>
		    </div>
		    <div v-if="order_detail.pay_way" class="order-line">
		      <p class="fs-weight">支付方式</p>
		      <p v-if="order_detail.pay_way == 1">现金支付</p>
		      <p v-if="order_detail.pay_way == 2">余额支付</p>
		      <p v-if="order_detail.pay_way == 3">积分支付</p>
		    </div>
		    <div class="order-line">
		      <p class="fs-weight">订单编号</p>
		      <p>{{order_detail.order_sn}}</p>
		    </div>
		    <div class="order-line">
		      <p class="fs-weight">下单时间</p>
		      <p>{{order_detail.create_time | formatDateTimeHm}}</p>
		    </div>
        <template v-if="order_detail.is_pick_up == 1">
          <div
            class="order-line"
            v-if="order_detail.check_code && order_detail.check_code !== ''"
          >
            <p class="fs-weight">核销码</p>
            <p>{{ order_detail.check_code }}</p>
          </div>
          <div
            class="order-line-img"
            v-if="order_detail.qrcode_img && order_detail.qrcode_img !== ''"
          >
            <p class="fs-weight">核销二维码</p>
            <img
              :src="order_detail.qrcode_img"
              alt=""
              width="100"
              height="100"
            />
          </div>
        </template>
		  </div>
		</div>
		
		<div v-if="haveDone === 0" class="order-btn">
		  <order-btn
				:orderState="order_detail.order_status"
				:orderData="order_detail"
				:orderType="order_detail.type"
			></order-btn>
		</div>
	</div>
</template>

<script>
import orderGoods from "@/components/order/orderGoods";
// import FillLogistics from "@/components/common/FillLogistics"
import HeadNav from "@/components/common/HeadNav";
import orderBtn from "@/components/order/orderBtn";
import groupMember from "@/components/order/groupMember";
import {orderMixin} from "@/mixins/orderMixin.js";
import {focusMixin} from "@/mixins/scrollerMixin";
import {orderDetail, afterSaleDetail, getLogisticsInfo} from "@/services/orderApi.js";
import {getGroupUserList} from "@/services/index.js";
import {getStore} from "@/utils/common.js";
import {mapState} from 'vuex';

export default {
  name: "order_detail",
  mixins: [orderMixin, focusMixin],
  components: {orderGoods, orderBtn, groupMember, HeadNav},
  data() {
    return {
      order_id: this.$route.query.order_id,
      order_detail: null,
      ORDER_TYPE: 1, // ORDER_TYPE 1: 普通商品订单，  2：售后订单
      reasonImgs: [],
      haveDone: 0,   // 是否已经倒计时结束
      group_data: {},   // 团购数据
      logistics_info: '',//物流信息
      logistics_list: '',
      logistics_status: '',
      date: '',
      time: '',
			infoStatus: false // 收货人信息显隐
    }
  },
  methods: {
    //查看物流
    lookMaterial() {
      this.$router.push({
        path: "/material-flow",
        query: {
          order_id: this.order_id
        },
      });
    },
    countDone() {
      this.haveDone = 1
    },
    orderCollect() {
      this.$router.push({
        path: "/order-collect",
        query: {
          pay_order_id: this.order_detail.pay_order_id,
          haveDone: this.haveDone,
        }
      });
    },
    async getOrderDetail() {
      try {
        this.ORDER_TYPE = getStore("ORDER_TYPE");
        let data;
        if (this.ORDER_TYPE == 1) {
          data = await orderDetail({
						order_id: this.order_id
					});
        } else if (this.ORDER_TYPE == 2) {
          let order_status = this.$route.query.order_status;
          data = await afterSaleDetail(this.order_id, order_status);
        }
        if (data.code === 0) {
          this.order_detail = data.data;
					
					this.infoStatus = !this.order_detail.items &&
														this.getVirtualStatus(this.order_detail.item_list[0].source_type);
					
          if (this.order_detail.group_buy_open_id) {
            let group_buy_open_id = this.order_detail.group_buy_open_id
            let group_data = await getGroupUserList(group_buy_open_id);
            this.group_data = group_data.data
          }
          if (this.order_detail.proof) {
            this.reasonImgs = this.order_detail.proof.split(",");
          }
          //是否获取物流
          let ORDER_TYPE = getStore("ORDER_TYPE");
          let order_status = this.order_detail.order_status;
          if (order_status >= 2 && ORDER_TYPE == 1 && (this.order_detail.item_list[0].source_type != 6 && this.order_detail.item_list[0].source_type != 8)) {
            this.getLogisticsInfo();
          }
        }
      } catch (err) {
        throw err;
      }
    },
    /**
     * 获取物流信息
     * @returns {Promise<void>}
     */
    async getLogisticsInfo() {
      try {
        const res = await getLogisticsInfo({
					order_id: this.order_id
				});
        if (res.code === 0) {
          const data = res.data;
          //苏宁
          console.log(data);
          if (data.source_type) {
            if (data.orderLogistics.length > 0) {
              this.logistics_status = 0
              this.logistics_list = data.orderLogistics.reverse();
              ;
              this.date = this.logistics_list[0].time.slice(5, 10);
              this.time = this.logistics_list[0].time.slice(11, 16)
            }
          } else {
            this.logistics_status = Number.parseInt(data.status)
            if (data.status == 0) {
              this.logistics_info = data.result;
              if (data.result.list.length > 0) {
                this.logistics_list = data.result.list;
                this.date = this.logistics_list[0].time.slice(5, 10);
                this.time = this.logistics_list[0].time.slice(11, 16)
              }
            } else {
              this.logistics_info = '暂无物流信息'
            }
          }
        }
      } catch (e) {

      }
    },
  },
  created() {
		try {
				var bfWorker = new Worker(window.URL.createObjectURL(new Blob(['1'])));
				window.addEventListener('unload',function () {
				// 这里绑个事件，构造一个闭包，以免 worker 被垃圾回收导致逻辑失效
				bfWorker.terminate();
				});
		}
		catch (e) {
		// if you want to do something here.
		}
    this.getOrderDetail();
    document.title = "订单详情";
  },
  computed: {
    groupNum() {
      let num = 0;
      for (let i = 0; i < this.groupList.length; i++) {
        if (!!this.groupList[i].user_id) {
          num++;
        }
      }
      return num;
    },
    ...mapState({
      type: state => state.businessInfo.type,//1现金商城，2积分商品，3内采商城
      integralRate: state => state.config.integral_rate // 积分比例
    }),
  }
};
</script>
<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.order-detail {
  padding-bottom: 1rem;
  box-sizing: border-box;

  .order-top {
    width: 100%;
    height: 1rem;
    background: var(--main-color);
    padding: 0 0.3rem 0 0.24rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-left {
      img {
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 6px;
      }

      display: flex;
      align-items: center;
      font-size: 0.3rem;
      color: #fff;
    }

    .top-right {
      color: #fff;
      font-size: 0.3rem;
    }
  }

  .order-address {
    height: 1.6rem;
    background: #fff;
    position: relative;
    padding: 0.36rem 0.3rem;
    box-sizing: border-box;
    line-height: 0.4rem;

    .user-info {
      font-size: 0.4rem;
      color: @color-dark-grey;
      margin-bottom: 3px;

      .user-name {
        margin-right: 0.26rem;
      }
    }

    .user-address {
      font-size: 0.22rem;
      color: #666666;
    }

    .order-line {
      height: 3px;
      width: 100%;
      background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .material-box {
    padding: 0.3rem;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    align-items: center;
    color: #333333;

    .material-time {
      width: 0.86rem;
      margin-right: 0.6rem;

      p {
        text-align: center;
        white-space: nowrap;
      }

      p:nth-child(1) {
        font-size: 0.28rem;
        line-height: 0.4rem;
      }

      p:nth-child(2) {
        font-size: 0.22rem;
        line-height: 0.32rem;
      }
    }

    .material-info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 0.36rem;
        height: 0.36rem;
      }

      .info {
        p:nth-child(1) {
          font-size: 0.28rem;
          line-height: 0.4rem;
        }

        p:nth-child(2) {
          font-size: 0.24rem;
          line-height: 0.34rem;
        }
      }
    }
  }

  .order-line {
    border-bottom: 1px solid @color-row-line;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.28rem;
    color: @color-dark-grey;
  }

  .order-line-img {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0;
  }

  .order-content {
    padding: 0.2rem 0.3rem;
    box-sizing: border-box;

    .goods-shop {
      padding: 0.2rem;
      box-sizing: border-box;
      font-size: 0.28rem;
      color: @color-dark-grey;
      position: relative;

      &::before {
        content: "";
        width: 2px;
        height: 0.2rem;
        background: var(--main-color);
        position: absolute;
        top: 50%;
        margin-top: -0.1rem;
        left: 0;
      }
    }

    .order-info {
      padding: 0 0.2rem 0.05rem 0.2rem;
      box-sizing: border-box;
      background: #fff;
      margin-bottom: 0.3rem;

      .info-title {
        font-size: 0.3rem;
        color: @color-dark-grey;
        padding: 0.2rem 0;
        border-bottom: 1px solid @color-row-line;
        margin-bottom: 0.2rem;
      }

      .info-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        color: @color-dark-grey;
        margin-bottom: 0.2rem;
      }

      .info-all {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        font-size: 0.28rem;
        color: @color-dark-grey;
        height: 1rem;
        border-top: 1px solid @color-row-line;

        p {
          margin-left: 5px;
        }
      }

      .order-line {
        border-bottom: 1px solid @color-row-line;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        color: @color-dark-grey;
      }

      .order-line:nth-last-child(1) {
        border: none;
      }
    }

    .after-reason {
      background: #fff;
      padding: 0.3rem 0.2rem;
      box-sizing: border-box;

      .after-title {
        font-size: 0.28rem;
        margin-bottom: 0.3rem;
      }

      .reason-title {
        font-size: 0.28rem;
        color: #999999;
        margin-bottom: 0.2rem;
        line-height: 0.4rem;
      }

      .reason-imgs {
        display: flex;
        align-items: center;

        img {
          width: 1.4rem;
          height: 1.4rem;
          margin-right: 0.2rem;
        }
      }
    }

    .order-collect {
      width: 6.9rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.2rem;
      box-sizing: border-box;
      background: #fff;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
      font-size: 0.28rem;
      color: #333333;
      margin-bottom: 0.3rem;

      .look {
        color: #999999;
        display: flex;
        align-items: center;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-left: 4px;
        }
      }
    }
  }

  .order-btn {
    width: 100%;
    height: 1rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    padding-right: 0.3rem;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  }
}

.refuse-reason-wrap {
  width: calc(100% - .6rem);
  padding: .3rem .2rem .2rem;
  margin: .3rem auto 0;
  background-color: #fff;
  // box-shadow:0px 3px 20px 0px rgba(0,0,0,0.05);
  .title {
    font-size: 0.28rem;
    color: #333;
    line-height: .4rem;
    letter-spacing: -0.68px;
    margin-bottom: 0.3rem;
  }
  .refuse-reason {
    font-size: 0.28rem;
    color: #FF5252;
    line-height: .4rem;
    letter-spacing: -0.68px;
    word-wrap: break-word;
    word-break: break-all;
  }
}

.fs-weight {
  font-weight: bold;
}

.color-main {
  color: var(--main-color);
}
</style>
