var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.order_detail
    ? _c(
        "div",
        { staticClass: "order-detail" },
        [
          _c("HeadNav"),
          _c("div", { staticClass: "order-top" }, [
            _c("div", { staticClass: "top-left" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/icon_dingdan_daifukuan@2x.png"),
                  alt: "",
                },
              }),
              _vm.getOrderState(_vm.order_detail.order_status) == "待付款" &&
              _vm.type === 3 &&
              _vm.order_detail.is_password == 0
                ? _c("span", [_vm._v("待提交")])
                : _vm.getOrderState(_vm.order_detail.order_status) ==
                    "待付款" &&
                  _vm.type === 3 &&
                  _vm.order_detail.is_password == 1
                ? _c("span", [_vm._v("待审批")])
                : _c("span", [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.getOrderState(_vm.order_detail.order_status)
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "top-right" }, [
              _vm.getOrderKey(_vm.order_detail.order_status) === "PAY"
                ? _c(
                    "span",
                    [
                      _c(
                        "yd-countdown",
                        {
                          attrs: {
                            time: _vm.order_detail.close_time,
                            timetype: "timestamp",
                            callback: _vm.countDone,
                          },
                        },
                        [_vm._v("\n\t\t        {%m}:{%s}\n\t\t      ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm.infoStatus
            ? _c("div", { staticClass: "order-address" }, [
                _c("p", { staticClass: "user-info" }, [
                  _c("span", { staticClass: "user-name fs-weight" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.order_detail.realname) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                  _c("span", { staticClass: "user-phone fs-weight" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm._f("hideMobile")(_vm.order_detail.mobile)) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ]),
                _c("p", { staticClass: "user-address" }, [
                  _vm._v(_vm._s(_vm.order_detail.address)),
                ]),
                _c("div", { staticClass: "order-line" }),
              ])
            : _vm._e(),
          _vm.getOrderState(_vm.order_detail.order_status) == "已拒绝"
            ? _c("div", { staticClass: "refuse-reason-wrap" }, [
                _c("h4", { staticClass: "title fs-weight" }, [
                  _vm._v("拒绝理由"),
                ]),
                _c("p", { staticClass: "refuse-reason" }, [
                  _vm._v(_vm._s(_vm.order_detail.refuse_reason)),
                ]),
              ])
            : _vm._e(),
          _vm.logistics_list.length > 0 && _vm.logistics_status === 0
            ? _c(
                "div",
                {
                  staticClass: "material-box",
                  on: {
                    click: function ($event) {
                      return _vm.lookMaterial()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "material-time" }, [
                    _c("p", [_vm._v(_vm._s(_vm.date))]),
                    _c("p", [_vm._v(_vm._s(_vm.time))]),
                  ]),
                  _c("div", { staticClass: "material-info" }, [
                    _c("div", { staticClass: "info" }, [
                      _c("p", [_vm._v(_vm._s(_vm.logistics_list[0].status))]),
                    ]),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/ic_jinru_right_black@3x.png"),
                        alt: "",
                      },
                    }),
                  ]),
                ]
              )
            : _vm.logistics_status === 0 && !_vm.logistics_list
            ? _c(
                "div",
                {
                  staticClass: "material-box",
                  on: {
                    click: function ($event) {
                      return _vm.lookMaterial()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "material-info" }, [
                    _c("div", { staticClass: "info" }, [
                      _c("p", [_vm._v(_vm._s(_vm.logistics_info))]),
                    ]),
                    _c("img", {
                      attrs: {
                        src: require("@/assets/ic_jinru_right_black@3x.png"),
                        alt: "",
                      },
                    }),
                  ]),
                ]
              )
            : _vm.logistics_status === -1
            ? _c("div", { staticClass: "material-box" }, [
                _c("div", { staticClass: "material-info" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("p", [_vm._v(_vm._s(_vm.logistics_info))]),
                  ]),
                ]),
              ])
            : _vm._e(),
          !_vm.order_detail.items &&
          _vm.order_detail.item_list[0].recharge_type == 1 &&
          _vm.order_detail.kami
            ? _c("div", { staticClass: "material-box" }, [
                _c("div", { staticClass: "material-info" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("p", [_vm._v("卡密信息")]),
                    _c("p", [_vm._v(_vm._s(_vm.order_detail.kami))]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.group_data.id
            ? _c(
                "div",
                { staticStyle: { background: "#fff" } },
                [
                  _c("group-member", {
                    attrs: {
                      groupInfo: _vm.group_data,
                      pay_order_id: _vm.order_detail.pay_order_id,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.ORDER_TYPE == 2 && _vm.order_detail.return_express_number
            ? _c(
                "div",
                {
                  staticClass: "order-line",
                  staticStyle: { padding: "0 .3rem", background: "#fff" },
                },
                [
                  _c("p", { staticClass: "fs-weight" }, [
                    _vm._v("商家换货物流单号"),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.order_detail.return_express_number)),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "order-content" },
            [
              _vm.order_detail.items
                ? _c("div", { staticClass: "goods-shop fs-weight" }, [
                    _vm._v(_vm._s(_vm.getSourceType(_vm.order_detail.items))),
                  ])
                : _vm.order_detail.item_list
                ? _c("div", { staticClass: "goods-shop fs-weight" }, [
                    _vm._v(
                      _vm._s(_vm.getSourceType(_vm.order_detail.item_list)) +
                        "\n\t\t  "
                    ),
                  ])
                : _vm._e(),
              _c("order-goods", {
                attrs: {
                  goodsList:
                    _vm.order_detail.items || _vm.order_detail.item_list,
                },
              }),
              _vm.ORDER_TYPE == 1
                ? _c("div", { staticClass: "order-info" }, [
                    _c("div", { staticClass: "info-title fs-weight" }, [
                      _vm._v("商品信息"),
                    ]),
                    Number(_vm.order_detail.reduce_integral) ||
                    Number(_vm.order_detail.reduce)
                      ? _c(
                          "div",
                          { staticClass: "info-item" },
                          [
                            _vm.type == 2
                              ? [
                                  _c("p", [_vm._v("满减")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.order_detail.reduce_integral) +
                                        "积分"
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("p", [_vm._v("满减")]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.order_detail.reduce)),
                                  ]),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                    Number(_vm.order_detail.vouchers_integral) ||
                    Number(_vm.order_detail.vouchers_amount)
                      ? _c(
                          "div",
                          { staticClass: "info-item" },
                          [
                            _vm.type == 2
                              ? [
                                  _c("p", [_vm._v("优惠券")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.order_detail.vouchers_integral
                                      ) + "积分"
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("p", [_vm._v("优惠券")]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.order_detail.vouchers_amount)
                                    ),
                                  ]),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "info-item" },
                      [
                        _vm.type == 2
                          ? [
                              _c("p", [_vm._v("商品总价")]),
                              _vm.order_detail.payway === 3 ||
                              _vm.order_detail.payway === 10
                                ? _c("p", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.order_detail
                                            .integral_pay_amount != "0.00"
                                            ? `${_vm.order_detail.integral_pay_amount}元`
                                            : ``
                                        ) +
                                        "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.order_detail
                                            .integral_pay_amount != "0.00" &&
                                            _vm.order_detail.integral != "0.00"
                                            ? "+"
                                            : ""
                                        ) +
                                        "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.order_detail.integral != "0.00"
                                            ? `${_vm.order_detail.integral}积分`
                                            : ``
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        `${_vm.order_detail.total_amount}元`
                                      )
                                    ),
                                  ]),
                            ]
                          : [
                              _c("p", [_vm._v("商品总价")]),
                              _c("p", { staticClass: "fs-weight" }, [
                                _vm._v(
                                  "¥" + _vm._s(_vm.order_detail.total_amount)
                                ),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.ORDER_TYPE == 2
                ? _c("div", { staticClass: "order-info" }, [
                    _c("div", { staticClass: "order-line" }, [
                      _c("p", { staticClass: "fs-weight" }, [
                        _vm._v("申请理由"),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.order_detail.content))]),
                    ]),
                    _vm.order_detail.express_number
                      ? _c("div", { staticClass: "order-line" }, [
                          _c("p", { staticClass: "fs-weight" }, [
                            _vm._v("换货退回物流编号"),
                          ]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.order_detail.express_number)),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.order_detail.operate_type != 4,
                            expression: "order_detail.operate_type != 4",
                          },
                        ],
                        staticClass: "order-line",
                      },
                      [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("退款金额"),
                        ]),
                        _vm.type == 2
                          ? _c("p", [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.order_detail.refund_money != "0.00"
                                      ? `${_vm.order_detail.refund_money}元`
                                      : ``
                                  ) +
                                  "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.order_detail.refund_money != "0.00" &&
                                      _vm.order_detail.refund_integral != "0.00"
                                      ? "+"
                                      : ""
                                  ) +
                                  "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.order_detail.refund_integral != "0.00"
                                      ? `${_vm.order_detail.refund_integral}积分`
                                      : ``
                                  ) +
                                  "  \n\t\t\t\t\t"
                              ),
                            ])
                          : _c("p", [
                              _vm._v(
                                "￥" + _vm._s(_vm.order_detail.refund_money)
                              ),
                            ]),
                      ]
                    ),
                    _c("div", { staticClass: "order-line" }, [
                      _c("p", { staticClass: "fs-weight" }, [
                        _vm._v("申请时间"),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatDateTime")(
                              _vm.order_detail.create_time
                            )
                          )
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.ORDER_TYPE == 2
                ? _c("div", { staticClass: "after-reason" }, [
                    _c("div", { staticClass: "fs-weight after-title" }, [
                      _vm._v("申请原因说明"),
                    ]),
                    _c("p", { staticClass: "reason-title" }, [
                      _vm._v(_vm._s(_vm.order_detail.reason)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "reason-imgs" },
                      _vm._l(_vm.reasonImgs, function (item, index) {
                        return _c("img", {
                          key: index,
                          attrs: { src: item, alt: "" },
                        })
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.ORDER_TYPE == 1
                ? _c(
                    "div",
                    {
                      staticClass: "order-collect",
                      on: {
                        click: function ($event) {
                          return _vm.orderCollect()
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "fs-weight" }, [
                        _vm._v("订单汇总"),
                      ]),
                      _vm._m(0),
                    ]
                  )
                : _vm._e(),
              _vm.ORDER_TYPE == 1
                ? _c(
                    "div",
                    { staticClass: "order-info" },
                    [
                      _vm.order_detail.recharge_account
                        ? _c("div", { staticClass: "order-line" }, [
                            _c("p", { staticClass: "fs-weight" }, [
                              _vm._v("充值账号"),
                            ]),
                            _c("p", [
                              _vm._v(_vm._s(_vm.order_detail.recharge_account)),
                            ]),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "order-line" }, [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("订单备注"),
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.order_detail.user_remark))]),
                      ]),
                      _vm.order_detail.pay_way
                        ? _c("div", { staticClass: "order-line" }, [
                            _c("p", { staticClass: "fs-weight" }, [
                              _vm._v("支付方式"),
                            ]),
                            _vm.order_detail.pay_way == 1
                              ? _c("p", [_vm._v("现金支付")])
                              : _vm._e(),
                            _vm.order_detail.pay_way == 2
                              ? _c("p", [_vm._v("余额支付")])
                              : _vm._e(),
                            _vm.order_detail.pay_way == 3
                              ? _c("p", [_vm._v("积分支付")])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "order-line" }, [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("订单编号"),
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.order_detail.order_sn))]),
                      ]),
                      _c("div", { staticClass: "order-line" }, [
                        _c("p", { staticClass: "fs-weight" }, [
                          _vm._v("下单时间"),
                        ]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDateTimeHm")(
                                _vm.order_detail.create_time
                              )
                            )
                          ),
                        ]),
                      ]),
                      _vm.order_detail.is_pick_up == 1
                        ? [
                            _vm.order_detail.check_code &&
                            _vm.order_detail.check_code !== ""
                              ? _c("div", { staticClass: "order-line" }, [
                                  _c("p", { staticClass: "fs-weight" }, [
                                    _vm._v("核销码"),
                                  ]),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.order_detail.check_code)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.order_detail.qrcode_img &&
                            _vm.order_detail.qrcode_img !== ""
                              ? _c("div", { staticClass: "order-line-img" }, [
                                  _c("p", { staticClass: "fs-weight" }, [
                                    _vm._v("核销二维码"),
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      src: _vm.order_detail.qrcode_img,
                                      alt: "",
                                      width: "100",
                                      height: "100",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.haveDone === 0
            ? _c(
                "div",
                { staticClass: "order-btn" },
                [
                  _c("order-btn", {
                    attrs: {
                      orderState: _vm.order_detail.order_status,
                      orderData: _vm.order_detail,
                      orderType: _vm.order_detail.type,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "look" }, [
      _vm._v("\n\t\t      查看总订单\n\t\t      "),
      _c("img", {
        attrs: { src: require("@/assets/ic_arrow_right_gray@2x.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }