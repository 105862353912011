<template>
  <div class="group-info">
    <h3 class="title" v-if="groupInfo.group_buy_open_info.surplus_num == 0">
      <span class="gray-txt">拼团成功</span>
    </h3>
    <h3 class="title" v-else>
      <span class="gray-txt">还差</span>
      <span class="num">{{groupInfo.group_buy_open_info.surplus_num}}</span>
      <span class="gray-txt">人即可拼团成功</span>
    </h3>
    <div class="group-list-wrap">
      <ul class="group-list">
        <li
            v-for="(item, index) in groupInfo.group_buy_open_info.people_limit"
            :key="index"
            :class="{active: index < groupInfo.group_buy_user_list.length}"
        >
          <img :src="groupInfo.group_buy_user_list[index].user_info.heagurl" alt
               v-if="index < groupInfo.group_buy_user_list.length&&groupInfo.group_buy_user_list[index].user_info.heagurl">
          <img src="~@/assets/ic_head_default@3x.png" alt
               v-else>
          <span class="head-badge" v-if="index === 0">团长</span>
        </li>
      </ul>
      <p class="countdown-wrap" v-if="groupInfo.group_buy_open_info.surplus_num != 0">
        <span class="gray-txt" v-if="end_time!==0">剩余</span>
        <yd-countdown :time="end_time" timetype="timestamp" done-text="" format="{%h}:{%m}:{%s}">
          <span class="time">{%h}:{%m}:{%s}</span>
        </yd-countdown>
        <span class="gray-txt" v-if="end_time===0">已</span>
        <span class="gray-txt">失效</span>
      </p>
      <div class="group-btn" v-if="end_time!==0 && groupInfo.group_buy_open_info.surplus_num != 0" @click="showDialog">邀请好友</div>
    </div>
    <!--引导分享-->
    <div v-if="show_guide" class="guide-bg" @click="show_guide=false"></div>
    <div class="guide-box" v-if="show_guide">
      <div class="guide-top"></div>
      <div class="flex-center" @click="show_guide=false">
        <img class="guide-btn" src="../../assets/button_iknow@3x.png" alt>
      </div>
    </div>
  </div>
</template>
<script>
import {baseUrl} from "../../utils/env";
import '@/static/js/wxapi'//微信
export default {
  name: "groupMember",
  props: {
    groupInfo: {
      type: Object,
      default: () => {
      }
    },
    pay_order_id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      show_guide: false,
      end_time: '',
    };
  },
  methods: {
    showDialog() {
      this.show_guide = true;
      let self = this;
      let bid = window.localStorage.getItem("bid");
      let groupInfo = this.groupInfo;
      let pay_order_id = this.pay_order_id;
      let auto_url = encodeURIComponent(
        `group_buying?group_buy_open_id=${groupInfo.group_buy_open_id}`
      );
    },

    // 默认显示图片
    errorHeadImg(e) {
      e.target.src = require("@/assets/ic_head_default@3x.png");
    }
  },
  mounted() {
    this.end_time = this.groupInfo.group_buy_open_info.end_time;
    let now_time = (new Date().getTime() / 1000).toFixed(0); //当前时间
    if (this.end_time - now_time < 0) {
      this.end_time = 0
    }
  }
};
</script>
<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.group-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.34rem 0.3rem 0.3rem;

  .title {
    .sc(@fontsize-medium, @color-grey);
    line-height: 0.4rem;
    text-align: center;

    .num {
      margin: 0 0.08rem;
      color: var(--main-color);
      font-weight: bolder;
    }
  }

  .group-list-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 0.24rem 0.24rem 0.66rem;
    .bis("~@/assets/img_tuangou_bg_shixiao");
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);

    .group-list {
      position: relative;
      padding-top: 0.72rem;
      margin-bottom: 0.24rem;

      li {
        float: left;
        position: relative;
        .wh(0.8rem, 0.8rem);
        border-radius: 50%;
        z-index: 0;

        img {
          .wh(100%, 100%);
          border-radius: 50%;
          overflow: hidden;
        }

        .head-badge {
          .cl;
          bottom: -0.1rem;
          display: inline-block;
          .wh(0.56rem, 0.26rem);
          padding-top: 0.02rem;
          padding-left: 0.02rem;
          .sc(0.2rem, #fff);
          line-height: 0.26rem;
          text-align: center;
          background-color: var(--main-color);
          border-radius: 0.13rem;
          overflow: hidden;
          z-index: 3;
        }

        &.active {
          border: 1px solid var(--main-color);
          margin-right: 0.4rem;
          margin-bottom: 0.32rem;
        }

        & + li {
          margin-right: 0.4rem;
          margin-bottom: 0.32rem;
        }
      }
    }

    .countdown-wrap {
      display: flex;
      align-items: center;
      height: 0.4rem;

      .gray-txt {
        .sc(0.22rem, @color-light-grey);
        line-height: 0.32rem;
      }

      .time {
        margin: 0 0.08rem;
        .sc(@fontsize-medium, var(--main-color));
        line-height: 0.4rem;
        font-weight: bold;
      }
    }

    .group-btn {
      width: 2.4rem;
      height: 0.6rem;
      border-radius: 0.6rem;
      text-align: center;
      line-height: 0.6rem;
      color: #fff;
      background: var(--main-color);
      margin-top: 4px;
    }
  }

  /*引导分享*/

  .guide-bg {
    position: fixed;
    .wh(100%, 100%);
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 1;
  }

  .guide-box {
    position: fixed;
    .wh(100%, 100%);
    top: 0;
    left: 0;
    z-index: 2;

    .guide-top {
      .wh(4.5rem, 3.7rem);
      background: url("../../assets/img_share@3x.png") no-repeat;
      background-size: 100%;
      margin-left: 2.58rem;
      margin-bottom: 2.58rem;
    }

    .guide-btn {
      .wh(2.6rem, 0.9rem);
    }
  }
}
</style>
